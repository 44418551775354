/**
 * TPS
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * used for tracking whether it is on priority operation or idle or offline   auto = ACES intitiated  manual = manual override  online = not in priority operation  offline = device is offline - have not sent data in the last N period
 */
export type OperationStatus = 'manual' | 'offline' | 'auto' | 'online' | 'deleted';

export const OperationStatus = {
    Manual: 'manual' as OperationStatus,
    Offline: 'offline' as OperationStatus,
    Auto: 'auto' as OperationStatus,
    Online: 'online' as OperationStatus,
    Deleted: 'deleted' as OperationStatus
};

