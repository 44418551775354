import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntersectionPipes } from './intersections.pipe';
import { IntersectionPropertyPipe } from './intersectionProperty.pipe';
import { IntersectionCodePipe } from './intersectionCode.pipe';
import { IntersectionNamePipe } from './intersectionName.pipe';
import { IncidentPriorityDescriptionPipe } from './incidentPriorityDescription.pipe';
import { FilesizePipe } from './filesize.pipe';

@NgModule({
  declarations: [
    IntersectionPipes,
    IntersectionPropertyPipe,
    IntersectionCodePipe,
    IntersectionNamePipe,
    IncidentPriorityDescriptionPipe,
    FilesizePipe,
  ],
  imports: [CommonModule],
  exports: [
    IntersectionPipes,
    IntersectionPropertyPipe,
    IntersectionCodePipe,
    IntersectionNamePipe,
    IncidentPriorityDescriptionPipe,
    FilesizePipe,
  ],
})
export class CommonPipesModule {}
